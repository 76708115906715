import AboutUsImages from "./AboutUsImages";
import AdditionalButtons from "./AdditionalButtons";
import PresidentCard from "./PresidentCard";

const imagesData = [
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.09 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.10 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.10 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.11 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.11 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.16 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.17 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.17 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.18 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.18 PM (2).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.18 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.19 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.19 PM (2).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.19 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.20 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.21 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.21 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.24 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.26 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.26 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.27 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.30 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.30 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.31 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.32 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.32 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.33 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.33 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.34 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.34 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.35 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.35 PM (2).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.35 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.36 PM.jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.37 PM (1).jpeg",
  "aboutUs/WhatsApp Image 2025-03-14 at 8.41.37 PM.jpeg",
];

const buttonsData = [
  {
    href: "https://www.sutarzademahasangh.co.in/_files/ugd/198ed7_f6ac2147e7e149029ea6e852bb567aa9.pdf",
    title: "Ambsbha Ahawal",
  },
  {
    href: "https://www.sutarzademahasangh.co.in/_files/ugd/198ed7_a31425fa32ed4cab83a6992f1988491a.pdf",
    title: "Donation List",
  },
];

const presidentData = {
  title: "अध्यक्ष",
  name: "प्रा. डॉ. ज्योती प्रमोद राखुंडे",
  profile_url: require("../../assets/Dr Jyoti Pramod Rakunde_JPG.webp"),
  tellUs:
    "महाराष्ट्र विश्वकर्मा मय सुतार (झाडे) समाज महासंघा मध्ये आपले स्वागत आहे",
};

const AboutUs = ({
  president = presidentData,
  images = imagesData,
  buttons = buttonsData,
}) => (
  <section id="about" className="px-2">
    <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 md:grid-flow-col-dense">
      <div className="mx-4">
        <AboutUsImages images={images} />
      </div>
      <div className="mx-4">
        <PresidentCard president={president} />
      </div>
    </div>
    <div className="mx-4 my-6 border-2 rounded-xl">
      <AdditionalButtons buttons={buttons} />
    </div>
  </section>
);

export default AboutUs;
